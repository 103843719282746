import React from "react";
import styled from 'styled-components';
import bg from "../images/to-area-51.jpg";

const ComponentStyles = styled.section`

    background-color: black;
    background: url(${bg}) no-repeat center center; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  border: 10px solid #333333;

`; 

export default function Component1() {
  return (
      <ComponentStyles>
   
        <div class="container">
          <div id="inner">
            <h1>Where did the hitchhiker go?</h1>
          </div>
        </div>
     
    </ComponentStyles>
  );
};
