import React from "react";
import ReactPageScroller from "react-page-scroller";
import Component1 from "./Component1";
import Component2 from "./Component2";
import Component3 from "./Component3";
import Component4 from "./Component4";
import Component5 from "./Component5";

export default class Scroller extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 5,
      blockScroll: false,
    };
    this._pageScroller = null;
  }

  goToPage = (eventKey) => {
    this._pageScroller.goToPage(eventKey);
  };

  pageOnChange = (number) => {
    this.setState({
      currentPage: number,
      //blockScroll: number === 3
    });
  };

  toggleLock = () => {
    this.setState({
      blockScroll: !this.state.blockScroll,
    });
  };

  render() {
    return (
      <ReactPageScroller
        ref={(c) => (this._pageScroller = c)}
        pageOnChange={this.pageOnChange}
        //blockScrollUp={this.state.blockScroll}
        //blockScrollDown={this.state.blockScroll}
      >
        
        <Component3 />
        <Component2 />
        <Component1 />
      </ReactPageScroller>
    );
  }
}
