import { createGlobalStyle } from "styled-components";
import bg from "../images/to-area-51.jpg";

const GlobalStyles = createGlobalStyle`
html {
    font-size: 10px;
}
body{
    background-color: black;

    height: 100%;
  position: fixed;
  /* prevent overscroll bounce*/
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
div.container{
    width: 100%;
    //height:600px;
    text-align: center;
    
}
#inner {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
}
h1{
    color:chartreuse;
    font-family: 'Orbitron', sans-serif;
    font-size: 4rem;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
}



`;

export default GlobalStyles;
